import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { createHashHistory } from 'history';
import appReducer from '../reducer';

// Combine reducers
const reducers = combineReducers({
    appReducer:appReducer
});

const history = createHashHistory();

const composeEnhancers =
    typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        }) : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)));

const store = createStore(
    reducers,
    enhancer
);

export { store, history };
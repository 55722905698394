import React, { lazy, Suspense } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Link, useLocation } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhotoIcon from '@material-ui/icons/Photo';
import TableChartIcon from '@material-ui/icons/TableChart';
import GridOnIcon from '@material-ui/icons/GridOn';
import Paper from '@material-ui/core/Paper';

const PhotoScreen = lazy(() => import('../components/Photo'));
const Distribution = lazy(() => import('../components/Distribution'));
const Shelves = lazy(() => import('../components/Shelves'));

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
});

const indexToTabName = {
    "/": 0,
    "/Distribution": 1,
    "/Shelves":2
  };

const SuspenseLoading = () => <>Loading...</>;
const Routes = function Routes() {
    const classes = useStyles();
    const location = useLocation();
    const currentPath = location.pathname;
    const [value, setValue] = React.useState(indexToTabName[currentPath]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Suspense fallback={<SuspenseLoading />}>
            <Paper square className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="secondary"
                >
                    <Tab icon={<PhotoIcon />} component={Link} to='/' label="Photo" />
                    <Tab icon={<TableChartIcon />} component={Link} to='/Distribution' label="Distribution" />
                    <Tab icon={<GridOnIcon />} component={Link} to='/Shelves' label="Shelves" />
                </Tabs>
            </Paper>
            <Switch>
                <Route exact path='/' component={PhotoScreen} />
                <Route path='/Distribution' component={Distribution} />
                <Route path='/Shelves' component={Shelves} />
            </Switch>
        </Suspense>
    )
}

export default Routes
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, IconButton, AppBar, MenuItem, Menu, Checkbox, FormControlLabel } from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../redux/actions';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const Header = React.memo(function Header() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isPepsiAPI = useSelector(state => state.appReducer.isPepsiApi);

    const handleChange = (e) => {
        if (e.target.files.length) {
            dispatch({ type: actions.SET_OPEN_TAB, openTab: 0 });
            dispatch({ type: actions.SET_ANALYSIS_RESULT, data: null, error: null, loading: false });
            dispatch({
                type: actions.SET_UPLOADED_IMAGE, uploadedImage: {
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0],
                    imageName: e.target.files.name
                }
            });
        }
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const onAPISelect = (event) => {
        setAnchorEl(null);
        let value = event.target.name === 'Pepsi';
        dispatch({ type: actions.SET_IS_PEPSI_API, isPepsiApi: value });
    };

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={'primary-search-account-menu'}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem><FormControlLabel control={<Checkbox name="Pepsi" color="primary" checked={isPepsiAPI} onChange={onAPISelect} />} label='Pepsi' /></MenuItem>
            <MenuItem><FormControlLabel control={<Checkbox name="Freshpet" color="primary" checked={!isPepsiAPI} onChange={onAPISelect} />} label='Freshpet' /></MenuItem>
        </Menu>
    );

    return (<div><AppBar position="static">
        <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}> CoolR Vista </Typography>
            <IconButton aria-label="search" color="inherit">
                <div className="input--file">
                    <CameraAlt />
                    <input name="Select File" type="file" accept="image/*" capture="user" id="fileUpload" alt="Choose image" onChange={handleChange} />
                </div>
            </IconButton>
        </Toolbar>
    </AppBar>
        {renderMenu}
    </div>)
});

export default Header;
import React, { lazy, Suspense } from 'react'
import {Paper, Tabs, Tab, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {Photo, TableChart, GridOn} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../redux/actions';

const PhotoScreen = lazy(() => import('./Photo'));
const Distribution = lazy(() => import('./Distribution'));
const Shelves = lazy(() => import('./Shelves'));
const SuspenseLoading = () => <>Loading...</>;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography component="div" style={{ height: '100vh' }} >{children}</Typography>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


const TabView = React.memo(function TabView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let openTab = useSelector(state => state.appReducer.openTab);

  const handleChange = (event, newValue) => {
    dispatch({ type: actions.SET_OPEN_TAB, openTab: newValue });
  };

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={openTab}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
      >
        <Tab icon={<Photo />} label="Photo" {...a11yProps(0)} />
        <Tab icon={<TableChart />} label="Distribution" {...a11yProps(1)} />
        <Tab icon={<GridOn />} label="Shelves" {...a11yProps(2)} />
      </Tabs>
      <Suspense fallback={<SuspenseLoading />}>
        <TabPanel value={openTab} index={0} >
          <PhotoScreen />
        </TabPanel>
        <TabPanel value={openTab} index={1} >
          <Distribution />
        </TabPanel>
        <TabPanel value={openTab} index={2} >
          <Shelves />
        </TabPanel>
      </Suspense>
    </Paper>
  )
})

export default TabView
import actions from '../actions';

const initialState = {
    appTitleName: 'CoolR',
    analysisResult: { data: null, loading: false },
    uploadedImage: { preview: '', raw: '', imageName: '' },
    products: { data: null, loading: false },
    openTab: 0,
    selectedProduct: {},
    isPepsiApi: false
};

const assignState = (state, propName, action) => {
    const { type, ...data } = action;
    return { ...state, [propName]: Object.assign({}, state[propName], data) };
}

const assignStateValue = (state, action) => {
    return { ...state, [action.type]: action[action.type] };
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_ANALYSIS_RESULT:
            return assignState(state, 'analysisResult', action);
        case actions.SET_PRODUCTS:
            return assignState(state, 'products', action);
        case actions.SET_UPLOADED_IMAGE:
        case actions.SET_OPEN_TAB:
        case actions.SET_SELECTED_PRODUCT:
        case actions.SET_IS_PEPSI_API:
            return assignStateValue(state, action);
        default:
            return state;
    }
}

export default appReducer;



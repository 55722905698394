import React from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Header from './components/Header';
import TabView from './components/TabView';
import { SnackbarProvider } from 'notistack';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  }
}));
export default function App() {
  const classes = useStyles();
  return (
    <Provider store={store}>
      {/* <BrowserRouter> */}
        <div className={classes.root}>
          <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
            <Header />
            <TabView />
            {/* <Routes /> */}
          </SnackbarProvider>
        </div>
      {/* </BrowserRouter> */}
    </Provider>
  );
}